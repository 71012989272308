<template>
    <div class="footer">
        <div class="footer-content">
            <div class="company">
                <div class="company-left">
                    <div class="company-info-item">软著登字：第7870263号</div>
                    <div class="company-info-item">备案号：<a  target="view_window" href="http://beian.miit.gov.cn"  style="color: #dedede;text-decoration:none;"> 粤ICP备20069714号 </a></div>
                    <div class="company-info-item">公司地址：广东省深圳市宝安区美生慧谷产业园春谷7楼701</div>
                    <div class="company-info-item">Copyright 爱乐云（深圳）科技有限公司</div>
                </div>
                <div class="company-right">
                    <div class="company-qrCodeBox">
                        <img class="qrCode" :src="require('@/assets/images/workerCode250212.png')" />
                        <div class="scanCode-text">扫码添加顾问微信</div>
                    </div>
                    <div class="company-phone">
                        <img class="phone-icon" :src="require('@/assets/images/footer/phone.png')" />
                        <span>15728562024</span>
                    </div>
                </div>
            </div>
            <div class="authBox">
                <template v-for="(_,index) in 4" :key="index">
                    <img
                        class="authBox-img"
                        :src="require(`@/assets/images/footer/auth${index + 1}.png`)"
                    />
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.footer {
    overflow: hidden;
    background-color: #35424f;
    width: 100%;
    height: 342px;
    .footer-content {
        .maxWidth();
        .company {
            margin-top: 60px;
            color: #dedede;
            display: flex;
            justify-content: space-between;
            .company-right {
                text-align: right;
            }
            .company-info-item {
                font-size: 14px;
                line-height: 14px;
                margin-bottom: 32px;
            }
            .company-qrCodeBox {
                position: relative;
                width: 200px;
                margin-left: auto;
                .qrCode {
                    height: 72px;
                    width: 72px;
                    background-color: white;
                    margin-left: auto;
                    object-fit: contain;
                    overflow: hidden;
                    transform: scale(1.2);
                }
                .scanCode-text {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 14px;
                }
                .pseudoEle {
                    content: "";
                    position: absolute;
                    right: 72px;
                    height: 10px;
                    width: 90px;
                    border-left: 1px solid white;
                }
                &::after {
                    .pseudoEle();
                    top: 10px;
                    border-top: 1px solid white;
                }
                &::before {
                    .pseudoEle();
                    bottom: 10px;
                    border-bottom: 1px solid white;
                }
            }
            .company-phone {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: 18px;
                .phone-icon {
                    width: 19px;
                    height: 18px;
                    margin-right: 12px;
                }
            }
        }
        .authBox {
            margin-top: 2px;
            .authBox-img {
                width: 119px;
                height: 56px;
                margin-right: 55px;
            }
        }
    }
}
</style>
